<template>
  <div>
    <div v-if="isPc" class="app">
      <HeaderTop></HeaderTop>
      <router-view />
      <Footer></Footer>
    </div>
    <div v-else class="mobile">
      <HeadMi></HeadMi>
      <div class="min-main">
        <router-view />
      </div>
      <FooterMi></FooterMi>
    </div>
  </div>
</template>

<script>
import HeaderTop from "@/components/HeadTop.vue";
import Footer from "@/components/Footer.vue";
import HeadMi from "@/components/mobileCom/HeadMi.vue";
import FooterMi from "@/components/mobileCom/FooterMi.vue";

export default {
  components: {
    HeaderTop,
    Footer,
    HeadMi,
    FooterMi,
  },
  props: {},
  data() {
    return {
      isPc: true,
    };
  },
  watch: {},
  computed: {},
  mounted() {
    if (this.isMobile()) {
      this.isPc = false;
      if (!sessionStorage.getItem("phone_indexLocation")) {
        this.$router.replace("/mi");
      }
      sessionStorage.setItem("phone_indexLocation", 1);
    } else {
      sessionStorage.removeItem("phone_indexLocation");
      // if (this.$route.path != '/index') {
      //   this.$router.replace("/");
      // }
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  height: 100%;
  position: relative;
}
.mobile {
  width: 100%;
  height: 100%;
  position: relative;
}
.min-main {
  min-height: 50rem;
}
</style>
