const zh = {
  // *导航
  menu: {
    home: "首页",
    about: "关于我们",
    aboutSon: {
      aboutSon1: "公司简介",
      aboutSon2: "发展历程",
      aboutSon3: "企业合伙人",
    },
    serve: "核心服务",
    serveSon: {
      serveSon1: "全域策略",
      serveSon2: "全域诊断系统",
      serveSon3: "资源渠道",
      serveSon4: "合作伙伴",
    },
    show: "案例展示",
    showSon: {
      showSon1: "战略咨询服务",
      showSon2: "品牌全案服务",
      showSon3: "品牌孵化业务",
    },
    news: "公司动态",
    newsSon: "新闻动态",
    join: "加入我们",
    joinSon: {
      joinSon1: "团队协作",
      joinSon2: "招聘岗位",
    },
    en: "英文",
  },
  // *底部
  footer: {
    tel: "联系方式",
    mail: "招聘邮箱",
    // CompanyName: "上海众添网络科技有限公司",
  },
  // *复用很多此
  oftenWord: {
    learnMore: "了解更多",
  },
  // *首页
  index: {
    // 第一层
    firstTier: {
      title: "三大业务助力解决品牌成长困境",
      helpGen1: "战略咨询服务",
      helpGen2: "品牌全案服务",
      helpGen3: "品牌孵化服务",
      helpExp1: "许多著名品牌反馈，买了大咨询公司的200w咨询服务，落不了地，远不如我们的咨询报告落地有效，快速可实现",
      helpExp2: "在咨询业务的驱动下挖掘具备高成长性的有一定规模的品牌提供营销全案服务（脑的角色）",
      helpExp3: "孵化一些具备高成长性的0-1的品牌",
    },
    // 第二层 关于我们
    secondTier: {
      aboutTitle: "关于我们",
      aboutContent01: "上海众添网络科技有限公司",
      aboutContent02: "是一家真正用数据驱动做产品开发、做定位、做营销决策的公司",
      aboutContent03: "是一家具备咨询公司能力的新型广告公司",
      aboutContent04: "是一家致力于把传统的KPI指标营销转向ROI营销的公司",
      aboutContent20: "我们",
      aboutContent21: "为品牌提供数据化的咨询诊断服务（发现问题、分析问题、解决问题）",
      aboutContent22: "为广告公司提供数据支持做策略",
      aboutContent23: "为咨询公司提供数据支持做品牌定位",
      aboutContent24: "为TP公司提供数据支持做运营思路",
      aboutContent25: "我们是数据赋能全域营销解决方案提供商",
      aboutContent30: "我们的愿景是助力更多中国品牌民族品牌的成长！",
      aboutUs1: "公司成立于",
      aboutUs2: "服务团队已超过",
      aboutUs3: "合作公司",
    },
    //  第三层 发展历程
    thirdTier: {
      growTitle: "发展历程",
      growTap1: "SEO服务搜索起家",
      growContent1:
        "为知名品牌提供搜索引擎SEO服务，凭借强大的数据分析和市场洞察能力，通过专业数智化SEO搜索服务充分触达客群并转化流量，帮助品牌方在线上市场提升销量。",
      growTap2: "扩充专业电商团队",
      growContent2:
        "组建专业的电商运营服务团队，具有丰富的线上运营及管理经验和快速适应渠道规则的能力，通过对市场趋势的研判，把握品牌方向及调性定位，实现精细化运营促进用户转化和留存。",
      growTap3: "成立私域运营团队",
      growContent3:
        "为了深层次精细化运营高净值客户，以用户为中心，搭建私域流量生态系统，进行稳定高频的有效触达，从而达到转化复购的目的，形成交易闭环。现已成立上海、宁波、苏州、广州等多地分公司。",
      growTap4: "数智赋能全域营销",
      growContent4:
        "全域数智化服务，打开营销新领域。通过数智化运营体系建设，完成“电商运营+内容传播+数字营销+直播引流”一体化优质闭环服务，助力品牌快速实现线上布局与营销阵地的拓展，实现全渠道打通。至今服务团队300+，合作广告公司60+。",
    },
    //  第四层 业务布局
    fourthTier: {
      businessTitle: "业务布局",
      businessTap1: "我们在数字时代为品牌提供全方位的落地服务",
      businessTap2: "战略咨询服务",
      businessContent201: "为品牌增长提供咨询诊断报告",
      businessContent202: "客服支持、站内直播、",
      businessContent203: "站内执行、标准付费投放、",
      businessContent204: "设计匹配、关键词seo",
      businessTap3: "品牌全案服务",
      //专业主播&定制场景、策略定位、直播场控、流量优化
      businessContent301: "在咨询业务的驱动下挖掘具备高成长性的、有 一定规模的品牌提供营销全案服务(脑的角色)",
      businessContent302: "策略定位、直播场控、",
      businessContent303: "流量优化",
      businessTap4: "品牌孵化服务",
      businessContent4: "孵化一些具备高成长性的0-1的品牌",
      businessTap5: "内容营销",
      businessContent501: "微信、微博、B站、",
      businessContent502: "小红书、知乎、抖音",
      businessTap6: "搜索优化",
      // 百度、搜狗、360、微信、微博、bilibili、小红书、知乎、抖音
      businessContent601: "百度、搜狗、360、",
      businessContent602: "微信、微博、bilibili、",
      businessContent603: "小红书、知乎、抖音",
    },

    //  第五层 合作伙伴
    partnerTitle: "合作伙伴",

    // 第六层 精选案例
    showTitle: "精选案例",
  },
  // *关于我们
  aboutPage: {
    // 第三层
    corePerson: "团队核心成员",
    corePersonSon: {
      boss01: "SpreaderOne创始人&CEO",
      boss02: "SpreaderOne副总",
      boss03: "SpreaderOne运营副总",
    },
    // 第四层
    workLocation: "办公地点",
  },
  // *核心服务
  servePage: {
    //  第一层
    firstTier: {
      tacticsTitle: "数智化全域营销NDIPS模型",
      tacticsTitle01: "数智化全域营销NDIPS模型，可以帮助品牌方通过数据洞察，寻找受众最大群体",
      tacticsTitle02: "提炼出适合与受众沟通话术，解决品牌跟消费者沟通问题，从而实现高效增长和消费转换",
      serveTactics1: "咨询分析",
      serveTactics2: "定位包装",
      serveTactics3: "电商",
      serveTactics301: "精细化运营",
      serveTactics4: "全域内容",
      serveTactics401: "营销策略",
      serveTactics5: "全域搜索",
      serveTactics6: "私域营销",
    },
    // 第二层
    secondTier: {
      tacticsTap1: "界定问题是品牌营销成功的关键",
      tacticsContent1:
        "在品牌营销中，准确界定问题至关重要。广告公司通常基于客户的Brief工作，但发现这些品牌需求往往不是品牌的真实根本问题；实际上品牌需要做一次完整的、全面的诊断，含产品、定价、卖点、平台策略及定位等方面，本质上都是品牌跟消费者之间的沟通问题；发现问题、分析问题、解决问题的过程才能真正的解决生意上的问题。",
      tacticsTap2: "数据驱动的品牌营销决策",
      tacticsContent2:
        "我们的解题思路是首先拉取全面完整的多平台多维度的用户数据，根据用户视角的数据真实的分析出市场的趋势，用户的需求，摒弃专家思维，要用用户思维来分析市场，因为产品最终要卖给用户，不是卖给专家，全面全局的分析推导出真实的用户视角的市场分析，用户需求，根据用户视角的结果来指导营销动作。",
      tacticsContent201:
        "通过数据我们可以获得：市场整体格局、市场趋势、竞品分析、产品开发方向、机会点挖掘、定价策略、产品结构规划、价格体系规划、主流平台竞争策略、产品的品牌的定位、品牌故事梳理、营销卖点挖掘、传播策略等",
      tacticsTap3: "数据化决策与用户洞察的核心要点",
      tacticsContent3: "数据化决策、数据化思维，做产品、做研发、做营销策划、咨询都离不开对用户进行准确的洞察",
      tacticsContent301: "首先，取数的数据源，是不是来自充分竞争的环境；",
      tacticsContent302: "其次，数据样本数据量级是不是足够大以及是不是有用户购物决策过程中的行为数据；",
      tacticsContent303: "最后，能不能有效的分辨及清理数据噪音及干扰数据通过数据清洗获得有效的数据。",
      tacticsTap4: "营销是品牌跟消费者沟通的过程",
      tacticsTap401:
        "正确的用户洞察是成功营销的第一步。用户、竞品、销售的渠道都在持续不断发生变化；在如此多的变量下，如果不能掌握数据化思维、数据化决策的能力、及时的获取市场的变化、动态的调整营销决策，那么一个品牌的生存就会非常被动。",
      tacticsTap402:
        "我们致力于将数据化决策能力赋予更多拥抱数据化思维的品牌，帮助品牌更好的通过营销与消费者完成沟通，找到最大公约数的消费群体，找到最大公约数消费群体共鸣的认知共识，用消费者听得懂的语言帮助消费者做偏向品牌的消费决策，实现最大化的消费共识，打造爆品。",
    },
    // 第三层
    thirdTier: {
      diagTitle: "“数智”大数据全域诊断系统",
      diagContent1: "“数智”大数据全链路诊断系统于2015年建立，核心逻辑由Spreaderone创始人设计，最初为单纯的电商站内分析诊断工具。",
      diagContent2:
        "在2015年至今年，该系统基于原先的电商站内诊断能力加入了多种中国互联网平台指数以及Spreaderone自创舆情分析系统功能使该系统能够提供站内站外全域诊断分析能力。并经由多年对中国电商平台实操数据、中国多种互联网营销平台数据整合优化，目前该系统已经成长为成熟的电商到营销端全链路诊断工具同时提供对应的解决方案。",
      diagContent3: "至今服务200个以上品牌产品，超过80%的品牌在电商平台端获得了20%~500%的业绩增长。",
    },
    // 第四层
    fourthTier: {
      ditchTitle: "数智化赋能 为品牌提供全方位领航服务",
      ditchTap1: "团队",
      ditchContent1:
        "Spreaderone注重团队建设，拥有300多人的专业服务团队，涵盖从技术、运营、市场营销等全方位的服务需求。并且拥有丰富的行业经验，对客户的产品可以在短时间内进行大数据分析，根据客户产品的趋势进行定位，推广及运营。",
      ditchTap2: "渠道",
      ditchContent2:
        "在平台支持上，Spreaderone与各大电商平台都建立了良好密切的合作关系，如抖音、淘宝、天猫、京东、小红书、拼多多等，积累了丰富的平台资源，并且与各大平台和门户网站建立了长期的战略合作，助力品牌电商营销。",
      ditchTap3: "服务",
      ditchContent3:
        "在服务质量上，Spreaderone始终致力于为品牌商提供专业和优质的服务，用更强的责任心为客户提供全方位一站式的代运营服务，赋能企业转型，携手企业发展，引领企业创新。",
    },
  },
  // *案例展示
  caseShow: {
    mainTab: {
      project01: "所有项目",
      project02: "战略咨询服务",
      project03: "品牌全案服务",
      project04: "品牌孵化业务",

      showTitle1: "在我们服务期间获得增长的品牌有：",
      showTitle2: "联合利华、雅培、卡游、正官庄、纽迪希亚、RIO、布鲁可、美孚、菲婷丝等",

      showText1: "战略咨询服务",
      showText101:
        "30亿体量的洗护品牌菲婷丝销售下跌30%，从资生堂到菲婷丝品牌流量流失严重。通过数据分析，核心问题是类目错配，优秀同行都去美发护发赛道进行布局，拉新的流量均往新赛道迁移，老赛道只有品牌化心智的流量；即美发护发类目在体量和趋势上更优于洗护清洁类目，可选择美发护发类目重点布局，同时增加在此赛道的投入。其次，在预算分配上，老赛道只需要做品牌收割，投品销宝低成本承接，去新赛道投直通车等工具去拉新，不是把预算浪费在老赛道的竞价广告上去拉新。",
      showText102: "布鲁可品牌，在传统的大颗粒积木市场逐渐萎缩的情况，成功转战奥特曼积木盲盒，实现逆势高增长。",

      showText2: "品牌全案服务",
      showText201:
        "奥特曼卡片品牌卡游从零到月销1.1个亿，用了不到一年的时间，我们通过化整为零的多sku组合的方式做价格带覆盖，根据用户反馈建议卡册等新品的开发，逐步完善产品结构，甚至帮助品牌建立了分销机制，最终该品牌4年从亏损成长为年销售额大几十亿的品牌。",
      showText202:
        "正官庄在连续多年高营销投入下没有带来理想的销售额，我们提出人参食品化的概念，帮助品牌把重型滋补品变成轻型养生饮品，把产品从中老年消费群体增加中青年的消费群体，通过全案营销，打造了80-90后一边熬夜一边养生的话题内容，引爆单品红参石榴饮，实现从几亿到几十亿的增长。",
      showText3: "品牌孵化业务",
      showText301:
        "2023年，我们用自己的营销方法论，在抖音平台通过重新定义面雕行业的产品及定价，孵化出第一个新牌子医束美，用了一年的时间，完成了2个多亿的销售，2024年我们将多渠道多平台品牌化运作，争取完成10个亿的销售。",
    },
  },
  //* 加入我们
  joinPage: {
    // 第一层
    firstTier: {
      usTitle: "加入我们",
      usContent1: "因为我们有活力和复兴号般的自驱力",
      usContent2: "所以能用7年崛起，并领跑互联网电商赛道",
      usContent3: "因为我们坚持用户思维，关注效率与结果的实干",
      usContent4: "所以能时刻拥抱变化，持续保持饱满的创业精神",
      usContent5: "因为我们全力以赴有担当，围绕组织目标展开协作",
      usContent6: "所以建立起背靠背的信任，共谋共创使命必达",
    },
    // 第二层
    secondTier: {
      teamPhoto: "团队照片",
    },
    // 第三层
    thirdTier: {
      waitTitle: "我们在等你，一起逆成长",
      waitContent1: "SpreaderOne是一支充满能量，彼此成就",
      waitContent2: "相互鼓励，创造巨大价值的团队",
      waitContent3: "我们希望寻找到另外一群和我们相似的人",
      waitContent4: "面向未来，创造更多的可能",
      waitContent5: "「热爱生活」「主动探索」「互联网思维」",
      waitContent6: "是我们注重的美好品质",
    },
    // 第四层
    fourthTier: {
      recruitTitle: "加入我们的方式",
      joinWayTitle1: "BOSS",
      joinWayText1: "在线投递简历",
      joinWayTitle2: "邮箱",
      joinWayText2: "yanting.shan@spreaderone.com",

      education: "学 历：",
      number: "招聘人数：",
      major: "专 业：",
      place: "工作地点：",
      releaseTime: "发布时间：",
      jobPosition: "岗位职位：",
      jobRequirements: "岗位要求：",
    },
  },
};
export default zh;
