import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/views/index.vue";

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  {
    path: "/",
    redirect: "/index", //*重定向
  },
  {
    path: "/index",
    name: "index",
    component: index,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/views/index.vue')
  },
  // *关于我们
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about.vue"),
  },
  // *核心服务
  {
    path: "/serve",
    name: "serve",
    component: () => import("@/views/serve.vue"),
  },
  // *案例展示
  {
    path: "/caseShow",
    name: "caseShow",
    component: () => import("@/views/caseShow.vue"),
  },
  // *公司动态
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news.vue"),
  },
  // *加入我们
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/join.vue"),
  },
  {
    path: "/showSon",
    name: "showSon",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/showSon.vue"),
  },
  //*手机端===================================================================
  {
    path: "/mi",
    redirect: "/miIndex", //*重定向
  },
  // *手机端首页
  {
    path: "/miIndex",
    name: "miindex",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/phoneIndex.vue"),
  },
  // *手机端关于我们
  {
    path: "/miAbout",
    name: "miabout",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/phoneAbout.vue"),
  },
  // *手机端核心服务
  {
    path: "/miServe",
    name: "miserve",
    component: () => import("@/views/mobile/phoneServe.vue"),
  },
  // *手机端案例展示
  {
    path: "/miCaseShow",
    name: "miCaseShow",
    component: () => import("@/views/mobile/phoneCaseShow.vue"),
  },
  // *手机端公司动态
  {
    path: "/miNews",
    name: "minews",
    component: () => import("@/views/mobile/phoneNews.vue"),
  },
  // *手机端加入我们
  {
    path: "/miJoin",
    name: "miJoin",
    component: () => import("@/views/mobile/phoneJoin.vue"),
  },
  // *手机端详情
  {
    path: "/miShowSon",
    name: "miShowSon",
    component: () => import("@/views/mobile/phoneShowSon.vue"),
  },

  {
    path: "*", //* 表示其他所有hash地址
    name: "404",
    component: () => import("@/views/Error404"),
  },
];

const router = new VueRouter({
  routes,
});


router.beforeEach((to, from, next) => {
  // 在电脑端测试时候 手机 电脑切换可以自动切换
  // if (isIhone()) {
  //   if (to.path.indexOf('/index') !== -1) {
  //     next({path: to.path.replace('/index', '/miIndex')})
  //   }
  // } else {
  //   if (to.path.indexOf('/miIndex') !==-1) {
  //     next({path: to.path.replace('/miIndex', '/index')})
  //   }
  // }
  // chrome
  
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// // push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => console.log(err))
// }
// // replace
// VueRouter.prototype.replace = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }

// let isIhone = () => {
//   let flag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   );
//   return flag;
// }

export default router;
