<template>
  <div class="mi-footer font_0_85rem flex-def flex-between flex-wrap">
    <div>
      <router-link to="/miIndex">
        <img src="@/assets/images/index/logo_white.png" />
      </router-link>
      <div class="line-h24 mt30 mb20 colorfff">
        <p class="font_0_75rem">联系方式：</p>
        <p>18601606630</p>
        <p class="font_0_75rem">招聘邮箱：</p>
        <p>yanting.shan@spreaderone.com</p>
      </div>
    </div>
    <div class="line-h24 colorfff font_0_75rem">
      <p class="weight font_0_85rem">上海众添网络科技有限公司</p>
      <div class="line-h18">
        <p class="mt15">上海(总部):</p>
        <p class="">上海市普陀区真南路150号德必易园 B203</p>
        <p class="mt10">苏州：</p>
        <p class="">苏州市工业园区圆融时代广场24幢广发银行7楼筑梦之星E02</p>
        <p class="mt10">宁波：</p>
        <p class="">宁波市鄞州区南部商务区桑德兹国际801</p>
        <p class="mt10">广州：</p>
        <p class="">广州市番禺区东环街汪波三街南3号骏盈2栋301</p>
      </div>
      <!-- <p class="mt20 weight">宁波众添网络科技有限公司</p>
      <p class="weight">宁波麦芒网络科技有限公司</p>
      <p class="colorccc font_0_75rem">
        浙江省宁波市鄞州区首南街道天童南路568号 恒元商务大厦801
      </p> -->
    </div>
    <!-- 备案 -->
    <div class="footer-record colorfff font12 line-h20 flex-c flex-wrap">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号：沪ICP备16033001号</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备16033001号-1</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备16033001号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.mi-footer {
  padding: 3rem 2rem 0 3rem;
  background-color: #070f34;
  .footer-record {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    column-gap: 10px;
  }
}
</style>
