<template>
  <!-- 第五层 合作伙伴-->
  <div class="index-partner mb100 mt100">
    <h2 class="font42 align-c mb50">{{ $t("index.partnerTitle") }}</h2>
    <div class="flex-def flex-between">
      <div
        v-for="(item, index) in partnerImg1"
        :key="index"
        class="index-partner-box flex-def flex-col"
        :class="index !== partnerImg1.length - 1 ? 'index-partner-boeder' : ''"
      >
        <div v-for="(v, i) in item" :key="i" class="pl22 mt20">
          <img :src="v.img" />
        </div>
      </div>
    </div>
    <div class="align-c mt65">
      <el-button v-if="btnPartnerShow" type="primary" round @click="gotoPartner"
        >{{ $t("oftenWord.learnMore") }}<i class="el-icon-arrow-right"></i
      ></el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      //合作伙伴
      partnerImg1: [
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_01.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_02.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_03.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_04.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_05.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_06.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_07.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_08.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_09.png"),
          },
        ],
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_11.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_12.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_13.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_14.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_15.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_16.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_17.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_18.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_19.png"),
          },
        ],
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_21.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_22.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_23.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_24.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_25.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_26.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_27.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_28.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_29.png"),
          },
        ],
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_31.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_32.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_33.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_34.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_35.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_36.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_37.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_38.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_39.png"),
          },
        ],
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_41.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_42.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_43.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_44.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_45.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_46.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_47.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_48.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_49.png"),
          },
        ],
        [
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_51.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_52.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_53.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_54.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_55.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_56.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_57.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_58.png"),
          },
          {
            img: require("@/assets/images/index/brand_logo/brand_logo_59.png"),
          },
        ],
      ],
      btnPartnerShow: true,
    };
  },
  watch: {},
  computed: {},
  mounted() {
    if (this.$route.query.locationKey || this.$route.path == "/serve") {
      this.btnPartnerShow = false;
    }
  },
  methods: {
    gotoPartner() {
      this.$router.push({
        path: "/serve?locationKey=2-5",
      });
    },
  },
};
</script>

<style lang="less" scoped>
// *第五层
.index-partner {
  width: 1200px;
  margin: 0 auto;
  .index-partner-box {
    width: 168px;
  }
  .index-partner-boeder {
    border-right: 1px solid #d2d2d2;
  }
  img {
    width: 85px;
  }
}
</style>
