
<template>
  <div class="mi-header border-box">
    <div class="flex-c flex-between">
      <router-link to="/mi">
        <img src="@/assets/images/index/logo.png" />
      </router-link>
      <div class="mi-header-btn" @click.stop="openNav">
        <img
          v-if="navShow"
          @click="popupShow = true"
          src="@/assets/images/mobileImg/mobile-menu.png"
        />
        <img v-else src="@/assets/images/mobileImg/mobile-close.png" />
      </div>
    </div>
    <van-popup
      v-model="popupShow"
      position="left"
      @close="closePopup"
      :style="{ height: '100%', width: '75%' }"
    >
      <div class="mi-nav">
        <div v-for="(item, index) in navList" :key="index">
          <div
            class="mi-nav-first"
            :class="{ firstActivate: index == navSonShow }"
            @click.stop="openNavSon(index)"
          >
            <div class="flex-c flex-between">
              <span class="weight">{{ item.name }}</span>
              <div v-if="index !== navSonShow">
                <router-link to="/mi" v-if="index == 0">
                  <div class="nav-router" @click="popupShow = false"></div>
                  <!-- <img @click="popupShow = false" src="@/assets/images/mobileImg/mi_r_orange.png" /> -->
                </router-link>
              </div>
              <div v-else>
                <router-link to="/mi" v-if="index == 0">
                  <div class="nav-router-white" @click="popupShow = false"></div>
                  <!-- <img @click="popupShow = false" src="@/assets/images/mobileImg/mi_r_white.png" /> -->
                </router-link>
              </div>
              <i
                v-if="index !== 0 && index !== navSonShow"
                class="not-first el-icon-circle-plus"
              ></i>
              <router-link v-if="index !== 0 && index == navSonShow" :to="item.path">
                <div class="nav-router-white" @click="popupShow = false"></div>
                <!-- <img @click="popupShow = false" src="@/assets/images/mobileImg/mi_r_white.png" /> -->
              </router-link>
            </div>
          </div>
          <div class="mi-nav-second" v-if="index == navSonShow && navSonShow != 0">
            <div
              class="line-h30 font14"
              v-for="(itemSon, indexSon) in item.children"
              :key="indexSon"
              @click="goToMiSon(item.path, itemSon.id)"
            >
              {{ itemSon.sonName }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      navShow: true,
      popupShow: false, // *侧边导航
      navSonShow: null, // *展开的二级导航
      activateNum: null,
    };
  },
  watch: {},
  computed: {
    navList() {
      return [
        // *首页
        { name: this.$t("menu.home"), path: "/mi" },
        // *关于我们
        {
          name: this.$t("menu.about"),
          path: "/miAbout",
          showSon: false,
          children: [
            { sonName: this.$t("menu.aboutSon.aboutSon1"), id: "1-1" },
            { sonName: this.$t("menu.aboutSon.aboutSon2"), id: "1-2" },
            { sonName: this.$t("menu.aboutSon.aboutSon3"), id: "1-3" },
          ],
        },
        // *核心服务
        {
          name: this.$t("menu.serve"),
          path: "/miServe",
          showSon: false,
          children: [
            { sonName: this.$t("menu.serveSon.serveSon1"), id: "2-1" },
            { sonName: this.$t("menu.serveSon.serveSon2"), id: "2-2" },
            { sonName: this.$t("menu.serveSon.serveSon3"), id: "2-3" },
            { sonName: this.$t("menu.serveSon.serveSon4"), id: "2-4" },
          ],
        },
        // *案例展示
        {
          name: this.$t("menu.show"),
          path: "/miCaseShow",
          showSon: false,
          children: [
            { sonName: this.$t("menu.showSon.showSon1"), id: "3-1" },
            { sonName: this.$t("menu.showSon.showSon2"), id: "3-2" },
            { sonName: this.$t("menu.showSon.showSon3"), id: "3-3" },
          ],
        },
        // *公司动态
        {
          name: this.$t("menu.news"),
          path: "/miNews",
          showSon: false,
          children: [{ sonName: this.$t("menu.newsSon"), id: "4-1" }],
        },
        // *加入我们
        {
          name: this.$t("menu.join"),
          path: "/miJoin",
          showSon: false,
          children: [
            { sonName: this.$t("menu.joinSon.joinSon1"), id: "5-1" },
            { sonName: this.$t("menu.joinSon.joinSon2"), id: "5-2" },
          ],
        },
      ];
    },
  },
  mounted() {
    this.activateNum = sessionStorage.getItem("scrollNum");
  },
  methods: {
    openNav() {
      this.navShow = !this.navShow;
    },
    closePopup() {
      this.navShow = true;
    },

    // *开二级菜单
    openNavSon(val) {
      if (this.navSonShow == val) {
        this.navSonShow = null;
      } else {
        this.navSonShow = val;
      }
    },
    // 跳转链接
    goToMiSon(path, val) {
      this.popupShow = false;
      if (this.activateNum == val) {
        this.$router.push({ path: path });
      } else {
        this.activateNum = val;
        sessionStorage.setItem("scrollNum", val);
      }

      setTimeout(() => {
        this.$router.push({ path: path, query: { locationKey: val } });
      }, 0);
    },
  },
};
</script>

<style lang="less" scoped>
.mi-header {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  padding: 3rem 1rem 0 1rem;
  .mi-header-btn {
    img {
      width: 40px;
    }
  }

  .mi-nav {
    color: #4c4c4c;
    padding-top: 1rem;

    .firstActivate {
      color: #fff;
      background-color: #e75012;
    }
    .mi-nav-first {
      line-height: 3rem;
      padding: 0 2rem;
      img {
        width: 1.3rem;
      }
      .not-first {
        font-size: 1.6rem;
        color: #e75012;
      }
      .nav-router {
        width: 1.46rem;
        height: 1.46rem;
        border-radius: 50%;
        aspect-ratio: 1/1;
        background-color: #e75012;
        position: relative;
      }
      .nav-router::before {
        content: "";
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #fff;
        position: absolute;
        bottom: 21%;
        left: 42%;
      }
      .nav-router::after {
        content: "";
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #e75012;
        position: absolute;
        bottom: 25%;
        left: 35%;
      }

      .nav-router-white {
        width: 1.46rem;
        height: 1.46rem;
        border-radius: 50%;
        aspect-ratio: 1/1;
        background-color: #fff;
        position: relative;
      }
      .nav-router-white::before {
        content: "";
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #e75012;
        position: absolute;
        bottom: 21%;
        left: 42%;
      }
      .nav-router-white::after {
        content: "";
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #fff;
        position: absolute;
        bottom: 25%;
        left: 35%;
      }
    }
    .mi-nav-second {
      padding: 0.5rem 0 0.5rem 2rem;
      background-color: #f3f8fc;
    }
  }
}
// .arrowR {
//   position: relative;
// }
// .arrowR:after,
// .arrowR:before {
//   width: 0;
//   height: 0;
//   border: 10px solid transparent;
//   border-left-color: orange;
//   position: absolute;
//   content: "";
// }

// .arrowR:before {
//   top: 0;
//   left: -4px;
//   border-left-color: white;
//   z-index: 3;
// }
</style>
