<template>
  <div class="footer">
    <div class="footer-mian colorfff flex-def flex-between">
      <div>
        <router-link to="/">
          <img src="@/assets/images/index/logo_white.png" />
        </router-link>
        <div class="line-h30">
          <p class="mt50">联系方式：</p>
          <p>18601606630</p>
          <p class="mt15">招聘邮箱：</p>
          <p>yanting.shan@spreaderone.com</p>
        </div>
      </div>
      <div class="flex-def">
        <!-- <ul class="flex-def colorccc line-h36 font12">
          <li class="flex-def flex-col mr50">
            <router-link to="#" class="colorfff weight mb20 font16">{{
              $t("menu.about")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.aboutSon.aboutSon1")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.aboutSon.aboutSon2")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.aboutSon.aboutSon3")
            }}</router-link>
          </li>
          <li class="flex-def flex-col mr50">
            <router-link to="#" class="colorfff weight mb20 font16">{{
              $t("menu.serve")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.serveSon.serveSon1")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.serveSon.serveSon2")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.serveSon.serveSon3")
            }}</router-link>
            <router-link to="#">{{
              $t("menu.serveSon.serveSon4")
            }}</router-link>
          </li>
          <li class="flex-def flex-col mr50">
            <router-link to="#" class="colorfff weight mb20 font16">{{
              $t("menu.show")
            }}</router-link>
            <router-link to="#">{{ $t("menu.showSon.showSon1") }}</router-link>
            <router-link to="#">{{ $t("menu.showSon.showSon2") }}</router-link>
            <router-link to="#">{{ $t("menu.showSon.showSon3") }}</router-link>
            <router-link to="#">{{ $t("menu.showSon.showSon4") }}</router-link>
          </li>
          <li class="flex-def flex-col mr50">
            <router-link to="#" class="colorfff weight mb20 font16">{{
              $t("menu.news")
            }}</router-link>
            <router-link to="#">{{ $t("menu.newsSon") }}</router-link>
          </li>
          <li class="flex-def flex-col">
            <router-link to="#" class="colorfff weight mb20 font16">{{
              $t("menu.join")
            }}</router-link>
            <router-link to="#">{{ $t("menu.joinSon.joinSon1") }}</router-link>
            <router-link to="#">{{ $t("menu.joinSon.joinSon2") }}</router-link>
          </li>
        </ul> -->
        <div v-for="(item, index) in navList" :key="index" :class="index !== navList.length - 1 ? 'mr50' : ''">
          <div class="line-h36 weight">
            <router-link :to="item.path">
              <span class="font16">{{ item.name }}</span></router-link
            >
            <div class="line-h36 mt30" v-if="item.children">
              <div class="font12 colorccc pointer" v-for="v in item.children" :key="v.id">
                <div @click="linkBtn(item.path, v.id)">{{ v.sonName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-location font12">
        <p class="weight font16">上海众添网络科技有限公司</p>
        <div class="line-h18">
          <p class="mt30">上海(总部):</p>
          <p class="">上海市普陀区真南路150号德必易园 B203</p>
          <p class="mt10">苏州：</p>
          <p class="">苏州市工业园区圆融时代广场24幢广发银行7楼筑梦之星E02</p>
          <p class="mt10">宁波：</p>
          <p class="">宁波市鄞州区南部商务区桑德兹国际801</p>
          <p class="mt10">广州：</p>
          <p class="">广州市番禺区东环街汪波三街南3号骏盈2栋301</p>
        </div>
        <!-- <p class="weight mb10">宁波众添网络科技有限公司</p>
        <p class="weight mb15">宁波麦芒网络科技有限公司</p>
        <p class="font12 colorccc line-h20">
          浙江省宁波市鄞州区首南街道天童南路568号 恒元商务大厦801
        </p> -->
      </div>
    </div>
    <!-- 备案 -->
    <div class="footer-record colorfff font14 align-c">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号：沪ICP备16033001号</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="ml30">沪ICP备16033001号-1</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="ml30">沪ICP备16033001号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  created() {},
  mounted() {},
  data() {
    return {
      // navList: [
      //   // *首页
      //   // { name: this.$t("menu.home"), path: "/index" },
      //   // *关于我们
      //   {
      //     name: this.$t("menu.about"),
      //     path: "/about",
      //     children: [
      //       { sonName: this.$t("menu.aboutSon.aboutSon1"), id: "1-1" },
      //       { sonName: this.$t("menu.aboutSon.aboutSon2"), id: "1-2" },
      //       { sonName: this.$t("menu.aboutSon.aboutSon3"), id: "1-3" },
      //     ],
      //   },
      //   // *核心服务
      //   {
      //     name: this.$t("menu.serve"),
      //     path: "/serve",
      //     children: [
      //       { sonName: this.$t("menu.serveSon.serveSon1"), id: "2-1" },
      //       { sonName: this.$t("menu.serveSon.serveSon2"), id: "2-2" },
      //       { sonName: this.$t("menu.serveSon.serveSon3"), id: "2-3" },
      //       { sonName: this.$t("menu.serveSon.serveSon4"), id: "2-4" },
      //     ],
      //   },
      //   // *案例展示
      //   {
      //     name: this.$t("menu.show"),
      //     path: "/caseShow",
      //     children: [
      //       { sonName: this.$t("menu.showSon.showSon1"), id: "3-1" },
      //       { sonName: this.$t("menu.showSon.showSon2"), id: "3-2" },
      //       { sonName: this.$t("menu.showSon.showSon3"), id: "3-3" },
      //       { sonName: this.$t("menu.showSon.showSon4"), id: "3-4" },
      //     ],
      //   },
      //   // *公司动态
      //   {
      //     name: this.$t("menu.news"),
      //     path: "/news",
      //     children: [{ sonName: this.$t("menu.newsSon"), id: "4-1" }],
      //   },
      //   // *加入我们
      //   {
      //     name: this.$t("menu.join"),
      //     path: "/join",
      //     children: [
      //       { sonName: this.$t("menu.joinSon.joinSon1"), id: "5-1" },
      //       { sonName: this.$t("menu.joinSon.joinSon2"), id: "5-2" },
      //     ],
      //   },
      // ],
    };
  },
  watch: {},
  computed: {
    navList() {
      return [
        // *首页
        { name: this.$t("menu.home"), path: "/index" },
        // *关于我们
        {
          name: this.$t("menu.about"),
          path: "/about",
          children: [
            { sonName: this.$t("menu.aboutSon.aboutSon1"), id: "1-1" },
            { sonName: this.$t("menu.aboutSon.aboutSon2"), id: "1-2" },
            { sonName: this.$t("menu.aboutSon.aboutSon3"), id: "1-3" },
          ],
        },
        // *核心服务
        {
          name: this.$t("menu.serve"),
          path: "/serve",
          children: [
            { sonName: this.$t("menu.serveSon.serveSon1"), id: "2-1" },
            { sonName: this.$t("menu.serveSon.serveSon2"), id: "2-2" },
            { sonName: this.$t("menu.serveSon.serveSon3"), id: "2-3" },
            { sonName: this.$t("menu.serveSon.serveSon4"), id: "2-4" },
          ],
        },
        // *案例展示
        {
          name: this.$t("menu.show"),
          path: "/caseShow",
          children: [
            { sonName: this.$t("menu.showSon.showSon1"), id: "3-1" },
            { sonName: this.$t("menu.showSon.showSon2"), id: "3-2" },
            { sonName: this.$t("menu.showSon.showSon3"), id: "3-3" },
          ],
        },
        // *公司动态
        {
          name: this.$t("menu.news"),
          path: "/news",
          children: [{ sonName: this.$t("menu.newsSon"), id: "4-1" }],
        },
        // *加入我们
        {
          name: this.$t("menu.join"),
          path: "/join",
          children: [
            { sonName: this.$t("menu.joinSon.joinSon1"), id: "5-1" },
            { sonName: this.$t("menu.joinSon.joinSon2"), id: "5-2" },
          ],
        },
      ];
    },
  },
  methods: {
    // 跳转链接
    linkBtn(path, val) {
      if (this.activateColorSon != val) {
        this.$router.push({ path: path, query: { locationKey: val } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #070f34;
  padding-top: 100px;
  .footer-mian {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .footer-location {
    width: 300px;
  }
  .footer-record {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}
</style>
