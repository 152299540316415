import axios from "../utils/request";

// *获取所有banner
export let getBanners = (params) => axios.post("banner/getBanners", params);

// *案例管理
export let getProjectsList = (params) =>
  axios.post("project/getProjects", params);

// *获取新闻列表
export let getNewsList = (params) => axios.post("news/getNews", params);

//*通过id获取案例详情
export let getProjectById = (params) => axios.post("project/getById", params);

// *通过id获取新闻详情
export let getNewsById = (params) => axios.post("news/getById", params);

//* 招聘管理
export let getJobList = (params) => axios.post("jobs/getJobs", params);
