import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//*引入样式取消
import "./assets/styles/reset.css";
//*引入less  公共样式
import "./assets/styles/common.less";
//*引入国际化
import i18n from "./utils/vueIN";


// *引入element-ui
import './utils/element.js'

import "@/assets/element-variables.scss";

// *引入 vat-UI
import {
  Swipe,
  SwipeItem,
  Lazyload,
  Popup,
  Collapse,
  CollapseItem,
  Tab,
  Tabs,
  Empty,
  Loading,
} from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Loading);
Vue.use(Empty);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n, // 挂载
  render: (h) => h(App),
}).$mount("#app");
