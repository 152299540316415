<template>
  <div>
    <div class="index-about">
      <div class="index-about-main pt90 flex-c">
        <img src="@/assets/images/index/index_about_us.jpg" />
        <div class="ml80 font20 line-h26">
          <h2 class="font42 mb55">
            {{ $t("index.secondTier.aboutTitle") }}
          </h2>
          <div class="mb30 font18">
            <p>{{ $t("index.secondTier.aboutContent01") }}</p>
            <p>{{ $t("index.secondTier.aboutContent02") }}</p>
            <p>{{ $t("index.secondTier.aboutContent03") }}</p>
            <p>{{ $t("index.secondTier.aboutContent04") }}</p>
          </div>
          <div class="mb30 font18">
            <p>{{ $t("index.secondTier.aboutContent20") }}</p>
            <p>{{ $t("index.secondTier.aboutContent21") }}</p>
            <p>{{ $t("index.secondTier.aboutContent22") }}</p>
            <p>{{ $t("index.secondTier.aboutContent23") }}</p>
            <p>{{ $t("index.secondTier.aboutContent24") }}</p>
            <p>{{ $t("index.secondTier.aboutContent25") }}</p>
          </div>
          <div class="mb20 font18">
            <p>{{ $t("index.secondTier.aboutContent30") }}</p>
          </div>
          <el-button v-if="btnShow" type="primary" round @click="routerTo"
            >{{ $t("oftenWord.learnMore") }}<i class="el-icon-arrow-right"></i
          ></el-button>
          <div class="flex-c flex-between align-c mt65 color80">
            <div>
              <p>{{ $t("index.secondTier.aboutUs1") }}</p>
              <p class="mt20"><span class="colormain font46">2013</span>年</p>
            </div>
            <div>
              <p>{{ $t("index.secondTier.aboutUs2") }}</p>
              <p class="mt20"><span class="colormain font46">300</span>人</p>
            </div>
            <div>
              <p>{{ $t("index.secondTier.aboutUs3") }}</p>
              <p class="mt20"><span class="colormain font46">60+</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三层 发展历程-->
    <div class="index-grow">
      <div class="index-grow-box position-rel">
        <div class="index-grow-main bgc-fff position-abs">
          <h2 class="align-c font42 pt100">
            {{ $t("index.thirdTier.growTitle") }}
          </h2>
          <div class="index-grow-carousel flex-c">
            <img class="pointer" src="@/assets/images/index/index_carousel_l.png" @click="arrowClick('left')" />
            <div class="flex-1">
              <el-carousel indicator-position="outside" :interval="5000" arrow="never" ref="cardShow">
                <el-carousel-item v-for="(item, index) in gowImage" :key="index" :label="item.years">
                  <div class="flex-def pt10">
                    <img class="mr70" :src="item.img" />
                    <div>
                      <span class="index-grow-tap font30">{{ item.tap }}</span>
                      <p class="mt35 font20 indent-2em line-h36">
                        {{ item.content }}
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <img class="pointer" src="@/assets/images/index/index_carousel_r.png" @click="arrowClick('right')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  created() {},

  data() {
    return {
      //发展历程
      gowImage: [
        {
          img: require("@/assets/images/index/index_carousel_2013.png"),
          years: "2013 —————",
          tap: this.$t("index.thirdTier.growTap1"),
          content: this.$t("index.thirdTier.growContent1"),
        },
        {
          img: require("@/assets/images/index/index_carousel_2016.png"),
          years: "2016 —————",
          tap: this.$t("index.thirdTier.growTap2"),
          content: this.$t("index.thirdTier.growContent2"),
        },
        {
          img: require("@/assets/images/index/index_carousel_2019.png"),
          years: "2019 —————",
          tap: this.$t("index.thirdTier.growTap3"),
          content: this.$t("index.thirdTier.growContent3"),
        },
        {
          img: require("@/assets/images/index/index_carousel_2021.png"),
          years: "2021 —————",
          tap: this.$t("index.thirdTier.growTap4"),
          content: this.$t("index.thirdTier.growContent4"),
        },
      ],
      btnShow: true,
    };
  },
  mounted() {
    if (this.$route.query.locationKey || this.$route.path == "/about") {
      this.btnShow = false;
    }
  },
  watch: {},
  computed: {},
  methods: {
    // *轮播点击事件
    arrowClick(val) {
      if (val !== "right") {
        this.$refs.cardShow.prev();
      } else {
        this.$refs.cardShow.next();
      }
    },

    // *跳转页面
    routerTo() {
      this.$router.push({
        path: "/about?locationKey=1-1",
      });
    },
  },
};
</script>

<style lang="less" scoped>
//第二层
.index-about {
  height: 950px;
  background: #f3f8fd url("@/assets/images/index/index_about_bg.png") no-repeat center bottom;
  .index-about-main {
    width: 1200px;
    margin: 0 auto;

    img {
      width: 556px;
      height: 584px;
    }
  }
}
//第三层
.index-grow {
  width: 100%;
  height: 660px;
  .index-grow-box {
    width: 1300px;
    height: 740px;
    margin: 0 auto;
    top: -160px;

    .index-grow-main {
      width: 1300px;
      box-sizing: border-box;
      background: url("@/assets/images/index/index_carousel_bg.png") no-repeat;

      .index-grow-carousel {
        padding: 100px 90px;
        .index-grow-tap {
          padding: 5px 10px;
          line-height: 34px;
          border-radius: 25px;
          border: solid 1px #e75012;
        }
      }
    }
  }
}

// *组件
/deep/.el-carousel__indicators--labels .el-carousel__button {
  padding-right: 0;
  color: #808080;
  background-color: #f2f2f2;
  line-height: 40px;
  border-radius: 22px;
  border: 1px solid #808080;
  word-spacing: 30px;
  font-size: 20px;
}
/deep/.el-carousel .el-carousel__indicator.is-active {
  color: #fff;
  position: relative;
  top: -22px;
  word-spacing: 30px;
  font-size: 20px;
  button {
    border: 1px solid #e75012;
    background-color: #e75012;
    color: #fff;
  }
  button:hover {
    background-color: #e75012 !important;
  }
}

/deep/.el-carousel__container {
  width: 790px;
  margin: 0 auto;
}
/deep/.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  opacity: 1;
}
/deep/.el-carousel__indicators--labels {
  margin-top: 30px;
}
</style>
