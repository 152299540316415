<template>
  <div class="head-top">
    <div class="nav-menu flex-c flex-between">
      <router-link to="/">
        <img src="@/assets/images/index/logo.png" />
      </router-link>
      <div class="flex-c">
        <!-- 导航菜单 -->
        <div v-for="(item, index) in navList" :key="index">
          <div class="head-piece pointer align-c position-rel line-h36 weight">
            <router-link :to="item.path">
              <span
                class="font18"
                :class="{ colormain: activateColor == item.path }"
                @click="mainTitleActivate(item.path)"
                >{{ item.name }}</span
              ></router-link
            >
            <div
              class="head-piece-son position-abs align-l line-h30"
              v-if="item.children"
            >
              <div
                class="head-piece-grandson font16 indent-1em"
                :class="{ colormain: activateColorSon == v.id }"
                @click="soanTitleActivate(item.path, v.id)"
                v-for="v in item.children"
                :key="v.id"
              >
                <div @click="linkBtn(item.path, v.id)">{{ v.sonName }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div @click="swapWord">
          {{this.$i18n.locale == 'zh' ? 'EN' : 'ZH'}}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadTop",
  components: {},
  props: {},
  data() {
    return {
      activateColor: "/index",
      activateColorSon: null,
    };
  },
  watch: {
    $route: function ($route) {
      this.activateColor = $route.path;
      this.activateColorSon = $route.query.locationKey;

      // *案例展示详情改变主标题颜色
      if ($route.path == "/showSon" && $route.query.detailId) {
        this.activateColor = "/caseShow";
      } else if ($route.path == "/showSon" && $route.query.newDetailId) {
        this.activateColor = "/news";
      }
    },
  },
  computed: {
    navList() {
      return [
        // *首页
        { name: this.$t("menu.home"), path: "/index" },
        // *关于我们
        {
          name: this.$t("menu.about"),
          path: "/about",
          children: [
            { sonName: this.$t("menu.aboutSon.aboutSon1"), id: "1-1" },
            { sonName: this.$t("menu.aboutSon.aboutSon2"), id: "1-2" },
            { sonName: this.$t("menu.aboutSon.aboutSon3"), id: "1-3" },
          ],
        },
        // *核心服务
        {
          name: this.$t("menu.serve"),
          path: "/serve",
          children: [
            { sonName: this.$t("menu.serveSon.serveSon1"), id: "2-1" },
            { sonName: this.$t("menu.serveSon.serveSon2"), id: "2-2" },
            { sonName: this.$t("menu.serveSon.serveSon3"), id: "2-3" },
            { sonName: this.$t("menu.serveSon.serveSon4"), id: "2-5" },
          ],
        },
        // *案例展示
        {
          name: this.$t("menu.show"),
          path: "/caseShow",
          children: [
            { sonName: this.$t("menu.showSon.showSon1"), id: "3-1" },
            { sonName: this.$t("menu.showSon.showSon2"), id: "3-2" },
            { sonName: this.$t("menu.showSon.showSon3"), id: "3-3" },
          ],
        },
        // *公司动态
        {
          name: this.$t("menu.news"),
          path: "/news",
          children: [{ sonName: this.$t("menu.newsSon"), id: "4-1" }],
        },
        // *加入我们
        {
          name: this.$t("menu.join"),
          path: "/join",
          children: [
            { sonName: this.$t("menu.joinSon.joinSon1"), id: "5-1" },
            { sonName: this.$t("menu.joinSon.joinSon2"), id: "5-2" },
          ],
        },
      ];
    },
  },
  mounted() {
    let index = location.hash.lastIndexOf("?");
    let indexSon = location.hash.indexOf("=");
    if (index != "-1") {
      let str = location.hash.substring(0, index);
      let str1 = location.hash.substring(indexSon + 1, indexSon + index - 2);
      this.activateColor = str.replace("#", "");
      this.activateColorSon = str1.replace("=", "");
    } else {
      this.activateColor = location.hash.replace("#", "");
    }

    // *进入就掉以前选过的
    // this.$i18n.locale = localStorage.getItem("spreaderoneLang");
  },
  methods: {
    //*中英转换
    // swapWord() {
    //   this.$i18n.locale == "zh"
    //     ? (this.$i18n.locale = "en")
    //     : (this.$i18n.locale = "zh");
    //   // 把切换的存到本地
    //   localStorage.setItem("spreaderoneLang", this.$i18n.locale);
    // },

    // 跳转链接
    linkBtn(path, val) {
      if (this.activateColorSon != val) {
        this.$router.push({ path: path, query: { locationKey: val } });
      }
    },

    //*一级标题颜色
    mainTitleActivate(index) {
      this.activateColor = index;
      if ((index = "/index")) {
        this.activateColorSon = null;
      }
    },
    // *二级标题颜色
    soanTitleActivate(index, id) {
      this.activateColor = index;
      this.activateColorSon = id;
    },
  },
};
</script>

<style lang="less" scoped>
.head-top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  .nav-menu {
    width: 1200px;
    margin: 80px auto 0;
    color: #fff;

    .head-piece {
      width: 100px;
    }
    .head-piece:hover {
      span {
        color: #e75012;
      }
    }

    .head-piece:hover .head-piece-son {
      display: block;
    }
    .head-piece-grandson:hover {
      color: #e75012;
    }
    .head-piece-son {
      padding: 10px 0;
      width: 126px;
      // background-color: rgba(255, 255, 255, 0.1);
      display: none;
    }
  }
}
</style>
