// 创建文件en.js
const en = {
  // *导航
  menu: {
    home: "inedex",
    about: "AboutUs",
    aboutSon: {
      aboutSon1: "company",
      aboutSon2: "development",
      aboutSon3: "partner",
    },
    serve: "service",
    serveSon: {
      serveSon1: "strategy",
      serveSon2: "strategy1",
      serveSon3: "strategy2",
      serveSon4: "strategy3",
    },
    show: "caseShow",
    showSon: {
      showSon1: "Brand",
      showSon2: "Brand2",
      showSon3: "Brand3",
      showSon4: "Brand4",
    },
    news: "Dynamic",
    newsSon: "news",
    join: "join",
    joinSon: {
      joinSon1: "join1",
      joinSon2: "join2",
    },
    en: "英文",
  },
  // *底部
  footer: {
    tel: "联系方式",
    mail: "招聘邮箱",
    // CompanyName: "上海众添网络科技有限公司",
  },
  // *复用很多此
  oftenWord: {
    learnMore: "learn more",
  },
  // *首页
  index: {
    // 第一层
    firstTier: {
      title: "Digital intelligence empowers efficient decision-making in brand marketing",
      helpGen1: "Help the revival of traditional national cards",
      helpGen2: "Help emerging brands grow",
      helpGen3: "Help well-known brands grow",
      helpExp1:
        "Excavate the traditional culture and history of the brand, and gain insight into the combination of the brand and youth through the empowerment of digital intelligence, so as to create brand freshness and upgrade consumption experience. ",
      helpExp2:
        "Can truly realize the growth of the new brand depends on the ability to acquire new customers, the use of digital brand marketing means, identify the right media delivery channels, achieve precision touch depth penetration, to achieve rapid growth from 0 to 1.",
      helpExp3:
        "In the Internet era, both international brands and national brands need to rely on the wisdom of operation thinking, do a good job in brand strategy upgrading, and create core in-depth content to touch users for a long time, so as to achieve long-term growth. ",
    },
    // 第二层 关于我们
    secondTier: {
      aboutTitle: "About Us",
      aboutContent1:
        "Starting in 2013 to provide a well-known brand and advertising company SEO and search engine optimization services (including baidu, the little red book, zhihu, trill, weibo, WeChat), through the new system after data analysis, the domain search demand data, as well as a competitive product marketing data analysis, elaborating the electric operation service shang dynasty (Tmall, jingdong, trill), brand operation private domain, etc. ",
      aboutContent2:
        "Up to now, our service team has more than 300 people, and has served many well-known brands (Unilever, Lex, Zhonghua Toothpaste, Abbott, Zhengguanzhuang, etc.), and cooperated with more than 60 advertising companies, including many well-known 4A companies. ",
      aboutUs1: "the company was established in",
      aboutUs2: "The service team has exceeded",
      aboutUs3: "cooperative corporation",
    },
    //  第三层 发展历程
    thirdTier: {
      growTitle: "development",
      growTap1: "SEO service search started",
      growContent1:
        "Provide search engine SEO services for famous brands, with strong data analysis and market insight ability, through professional SEO search services to fully reach the customer base and transform traffic, help brands increase sales on the online market. ",
      growTap2: "扩充专业电商团队",
      growContent2:
        "组建专业的电商运营服务团队，具有丰富的线上运营及管理经验和快速适应渠道规则的能力，通过对市场趋势的研判，把握品牌方向及调性定位，实现精细化运营促进用户转化和留存。",
      growTap3: "成立私域运营团队",
      growContent3:
        "为了深层次精细化运营高净值客户，以用户为中心，搭建私域流量生态系统，进行稳定高频的有效触达，从而达到转化复购的目的，形成交易闭环。现已成立上海、宁波、苏州、广州等多地分公司。",
      growTap4: "数智赋能全域营销",
      growContent4:
        "全域数智化服务，打开营销新领域。通过数智化运营体系建设，完成“电商运营+内容传播+数字营销+直播引流”一体化优质闭环服务，助力品牌快速实现线上布局与营销阵地的拓展，实现全渠道打通。至今服务团队300+，合作广告公司60+。",
    },
    //  第四层 业务布局
    fourthTier: {
      businessTitle: "business layout",
      businessTap1: "We provide a full range of landing services for brands in the digital era",
      businessTap2: "TP e-commerce agent operation",
      // 策略定位、店铺营销、客服支持、站内直播、站内执行、标准付费投放、设计匹配、关键词seo
      businessContent201: "策略定位、店铺营销、",
      businessContent202: "客服支持、站内直播、",
      businessContent203: "站内执行、标准付费投放、",
      businessContent204: "设计匹配、关键词seo",
      businessTap3: "DP抖音直播代运营",
      //专业主播&定制场景、策略定位、直播场控、流量优化
      businessContent301: "专业主播&定制场景、",
      businessContent302: "策略定位、直播场控、",
      businessContent303: "流量优化",
      businessTap4: "私域运营",
      businessContent4: "自建私域管理、共享私域出货",
      businessTap5: "内容营销",
      businessContent501: "微信、微博、B站、",
      businessContent502: "小红书、知乎、抖音",
      businessTap6: "搜索优化",
      // 百度、搜狗、360、微信、微博、bilibili、小红书、知乎、抖音
      businessContent601: "百度、搜狗、360、",
      businessContent602: "微信、微博、bilibili、",
      businessContent603: "小红书、知乎、抖音",
    },

    //  第五层 合作伙伴
    partnerTitle: "partner",

    // 第六层 精选案例
    showTitle: "Selected case",
  },
  // *关于我们
  aboutPage: {
    // 第三层
    corePerson: "Core team member",
    corePersonSon: {
      boss01: "SpreaderOne创始人&CEO",
      boss02: "SpreaderOne副总",
      boss03: "SpreaderOne运营副总",
    },
    // 第四层
    workLocation: "office location",
  },
  // *核心服务
  servePage: {
    //  第一层
    firstTier: {
      tacticsTitle: "Digital intelligence enables the whole domain e-commerce strategy service",
      serveTactics1: "Consulting analysis",
      serveTactics2: "定位包装",
      serveTactics3: "电商",
      serveTactics301: "精细化运营",
      serveTactics4: "全域内容",
      serveTactics401: "营销策略",
      serveTactics5: "全域搜索",
      serveTactics6: "私域营销",
    },
    // 第二层
    secondTier: {
      tacticsTap1: "Data policy analysis report",
      tacticsContent1: "Search engine based data analysis and insights capture the key factors closest to consumer conversion. ",
      tacticsContent101:
        "通过电商平台的交易数据分析用户售后行为趋势，通过全域的用户搜索需求数据分析用户的售前需求趋势（平台量级，需求方向，内容方向）竞品，营销行为数据分析（投放量级，达人类型，爆文比例，内容方向）。",
      tacticsTap2: "数智化电商 全平台运营",
      tacticsContent2: "通过数据分析，辅助营销决策，帮助品牌做产品的包装定位，制定全域营销方案。",
      tacticsContent201:
        "通过天猫精细化运营，重新包装定位产品，通过销量验证用户需求大的痛点市场，对应产品的卖点，文案，设计，消费场景等，找出潜力爆款；全域电商平台精细化运营及增长计划建议。",
      tacticsTap3: "内容营销 全域种草",
      tacticsContent3:
        "根据数据报告，围绕电商营销关键策略，为爆品制定站外营销方案，种草平台进行种草，全域搜索布局，收割主动搜索的人群（主动搜索的人群是最接近销售转化的人群）。",
      tacticsContent301: "结合品牌推广节奏，站外内容营销布局在关键节点与电商平台主题互联互通，实现流量及GMV的增长。",
    },
    // 第三层
    thirdTier: {
      diagTitle: "“数智”大数据全域诊断系统",
      diagContent1: "“数智”大数据全链路诊断系统于2015年建立，核心逻辑由Spreaderone创始人设计，最初为单纯的电商站内分析诊断工具。",
      diagContent2:
        "在2015年至今年，该系统基于原先的电商站内诊断能力加入了多种中国互联网平台指数以及Spreaderone自创舆情分析系统功能使该系统能够提供站内站外全域诊断分析能力。并经由多年对中国电商平台实操数据、中国多种互联网营销平台数据整合优化，目前该系统已经成长为成熟的电商到营销端全链路诊断工具同时提供对应的解决方案。",
      diagContent3: "至今服务200个以上品牌产品，超过80%的品牌在电商平台端获得了20%~500%的业绩增长。",
    },
    // 第四层
    fourthTier: {
      ditchTitle: "数智化赋能 为品牌提供全方位领航服务",
      ditchTap1: "团队",
      ditchContent1:
        "Spreaderone注重团队建设，拥有300多人的专业服务团队，涵盖从技术、运营、市场营销等全方位的服务需求。并且拥有丰富的行业经验，对客户的产品可以在短时间内进行大数据分析，根据客户产品的趋势进行定位，推广及运营。",
      ditchTap2: "渠道",
      ditchContent2:
        "在平台支持上，Spreaderone与各大电商平台都建立了良好密切的合作关系，如抖音、淘宝、天猫、京东、小红书、拼多多等，积累了丰富的平台资源，并且与各大平台和门户网站建立了长期的战略合作，助力品牌电商营销。",
      ditchTap3: "服务",
      ditchContent3:
        "在服务质量上，Spreaderone始终致力于为品牌商提供专业和优质的服务，用更强的责任心为客户提供全方位一站式的代运营服务，赋能企业转型，携手企业发展，引领企业创新。",
    },
  },
  // *案例展示
  caseShow: {
    mainTab: {
      project01: "所有项目",
      project02: "战略咨询服务",
      project03: "品牌全案服务",
      project04: "品牌孵化业务",

      showTitle1: "在我们服务期间获得增长的品牌有：",
      showTitle2: "联合利华、雅培、卡游、正官庄、纽迪希亚、RIO、布鲁可、美孚、菲婷丝等",

      showText1: "战略咨询服务",
      showText101:
        "30亿体量的洗护品牌菲婷丝销售下跌30%，从资生堂到菲婷丝品牌流量流失严重。通过数据分析，核心问题是类目错配，优秀同行都去美发护发赛道进行布局，拉新的流量均往新赛道迁移，老赛道只有品牌化心智的流量；即美发护发类目在体量和趋势上更优于洗护清洁类目，可选择美发护发类目重点布局，同时增加在此赛道的投入。其次，在预算分配上，老赛道只需要做品牌收割，投品销宝低成本承接，去新赛道投直通车等工具去拉新，不是把预算浪费在老赛道的竞价广告上去拉新。",
      showText102: "布鲁可品牌，在传统的大颗粒积木市场逐渐萎缩的情况，成功转战奥特曼积木盲盒，实现逆势高增长。",

      showText2: "品牌全案服务",
      showText201:
        "奥特曼卡片品牌卡游从零到月销1.1个亿，用了不到一年的时间，我们通过化整为零的多sku组合的方式做价格带覆盖，根据用户反馈建议卡册等新品的开发，逐步完善产品结构，甚至帮助品牌建立了分销机制，最终该品牌4年从亏损成长为年销售额大几十亿的品牌。",
      showText202:
        "正官庄在连续多年高营销投入下没有带来理想的销售额，我们提出人参食品化的概念，帮助品牌把重型滋补品变成轻型养生饮品，把产品从中老年消费群体增加中青年的消费群体，通过全案营销，打造了80-90后一边熬夜一边养生的话题内容，引爆单品红参石榴饮，实现从几亿到几十亿的增长。",
      showText3: "品牌孵化业务",
      showText301:
        "2023年，我们用自己的营销方法论，在抖音平台通过重新定义面雕行业的产品及定价，孵化出第一个新牌子医束美，用了一年的时间，完成了2个多亿的销售，2024年我们将多渠道多平台品牌化运作，争取完成10个亿的销售。",
    },
  },
  //* 加入我们
  joinPage: {
    // 第一层
    firstTier: {
      usTitle: "join us",
      usContent1: "Because we have vitality and Fuxing drive",
      usContent2: "所以能用7年崛起，并领跑互联网电商赛道",
      usContent3: "因为我们坚持用户思维，关注效率与结果的实干",
      usContent4: "所以能时刻拥抱变化，持续保持饱满的创业精神",
      usContent5: "因为我们全力以赴有担当，围绕组织目标展开协作",
      usContent6: "所以建立起背靠背的信任，共谋共创使命必达",
    },
    // 第二层
    secondTier: {
      teamPhoto: "Group photo",
    },
    // 第三层
    thirdTier: {
      waitTitle: "We are waiting for you to grow up together",
      waitContent1: "SpreaderOne是一支充满能量，彼此成就",
      waitContent2: "相互鼓励，创造巨大价值的团队",
      waitContent3: "我们希望寻找到另外一群和我们相似的人",
      waitContent4: "面向未来，创造更多的可能",
      waitContent5: "「热爱生活」「主动探索」「互联网思维」",
      waitContent6: "是我们注重的美好品质",
    },
    // 第四层
    fourthTier: {
      recruitTitle: "Position Available:",
      education: "学 历：",
      number: "招聘人数：",
      major: "专 业：",
      place: "工作地点：",
      releaseTime: "发布时间：",
      jobPosition: "岗位职位：",
      jobRequirements: "岗位要求：",
    },
  },
};
export default en;
