import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/base.css';
import {
    Button,
    Icon,
    Carousel,
    CarouselItem,
    Pagination,
    Message,
    Loading,
    Input,
} from "element-ui";

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.use(Button);
Vue.use(Icon);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Input);

Vue.prototype.$message = Message;