import axios from "axios";

// *引入element-ui的message组件
import { Message } from "element-ui";

//*全局路径
export let apiAll = "https://api.spreaderone.com/"; // *线上
// export let apiAll = "http://106.15.251.172:8888/"; // *预生产
// export let apiAll ="http://192.168.22.127:8080/" // *本地

// 创建axios实例
const instance = axios.create({
  baseURL: apiAll,
  timeout: 100000,
});

// request-interceptor
instance.interceptors.request.use(
  (config) => {
    // config.headers["token"] = "C0496C125D7214B1CB9BE444DBD4D1DC";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      response.data = null;
    }
    return response;
  },
  (error) => {
    const err = error.response;
    if (err.status === 500) {
      Message({
        message: "服务器异常",
        type: "error",
      });
    }
  }
);

export default instance;
