<template>
  <div class="index">
    <div class="index-banner">
      <el-carousel height="982px" arrow="never" :interval="5000" class="align-c">
        <el-carousel-item v-for="(item, index) in imgBarnner" :key="index">
          <img :src="imgUrl + 'images/' + item.filePath" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="index-main">
      <!-- 第一层 -->
      <div class="index-decision mb100">
        <h2 class="align-c font42 mt100 mb70">
          {{ $t("index.firstTier.title") }}
        </h2>
        <div class="flex-c flex-between font20">
          <div class="index-decision-main position-rel">
            <div class="index-decision-box align-c flex-c flex-col flex-center">
              <img class="mb20" src="@/assets/images/index/index_help_01.png" />
              <p class="mt20 font30">{{ $t("index.firstTier.helpGen1") }}</p>
            </div>
            <div class="index-box-bg position-abs colorfff">
              <h2 class="font30 mb25">{{ $t("index.firstTier.helpGen1") }}</h2>
              <p class="line-h26">{{ $t("index.firstTier.helpExp1") }}</p>
            </div>
          </div>
          <div class="index-decision-main position-rel">
            <div class="index-decision-box align-c flex-c flex-col flex-center">
              <img class="mb20" src="@/assets/images/index/index_help_02.png" />
              <p class="mt20 font30">{{ $t("index.firstTier.helpGen2") }}</p>
            </div>
            <div class="index-box-bg position-abs colorfff">
              <h2 class="font30 mb25">{{ $t("index.firstTier.helpGen2") }}</h2>
              <p class="line-h26">{{ $t("index.firstTier.helpExp2") }}</p>
            </div>
          </div>
          <div class="index-decision-main position-rel">
            <div class="index-decision-box align-c flex-c flex-col flex-center">
              <img class="mb20" src="@/assets/images/index/index_help_03.png" />
              <p class="mt20 font30">{{ $t("index.firstTier.helpGen3") }}</p>
            </div>
            <div class="index-box-bg position-abs colorfff">
              <h2 class="font30 mb25">{{ $t("index.firstTier.helpGen3") }}</h2>
              <p class="line-h26">{{ $t("index.firstTier.helpExp3") }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二层 关于我们-->
      <!-- <div class="index-about mb100 position-rel">
        <div class="index-about-main pt90 flex-def">
          <img src="@/assets/images/index/index_about_us.jpg" />
          <div>
            <h2 class="align-c font42">
              {{ $t("index.secondTier.aboutTitle") }}
            </h2>
            <p>{{ $t("index.secondTier.aboutContent1") }}</p>
            <p>{{ $t("index.secondTier.aboutContent2") }}</p>
            <el-button type="primary" round
              >{{ $t("oftenWord.learnMore") }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <div class="flex-c flex-between align-c">
              <div>
                <p>{{ $t("index.secondTier.aboutUs1") }}</p>
                <p>2013年</p>
              </div>
              <div>
                <p>{{ $t("index.secondTier.aboutUs2") }}</p>
                <p>300人</p>
              </div>
              <div>
                <p>{{ $t("index.secondTier.aboutUs3") }}</p>
                <p>60+</p>
              </div>
            </div>
          </div>
        </div>
        <div class="index-grow position-abs">
          <div class="index-grow-main bgc-fff">
            <h2 class="align-c font42 pt100">
              {{ $t("index.thirdTier.growTitle") }}
            </h2>
            <div class="index-grow-carousel flex-c">
              <img
                src="@/assets/images/index/index_carousel_l.png"
                @click="arrowClick('right')"
              />
              <div class="flex-1">
                <el-carousel
                  indicator-position="outside"
                  :interval="5000"
                  arrow="never"
                  ref="cardShow"
                >
                  <el-carousel-item
                    v-for="(item, index) in gowImage"
                    :key="index"
                    :label="item.years"
                  >
                    <div class="flex-c">
                      <img :src="item.img" />
                      <div>
                        <span>{{ item.tap }}</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <img
                src="@/assets/images/index/index_carousel_r.png"
                @click="arrowClick('left')"
              />
            </div>
          </div>
        </div>
      </div> -->

      <AboutElement></AboutElement>
      <!-- 第四层 业务布局-->
      <div class="index-business mb100">
        <div class="index-business-main font16">
          <div class="align-c pt100">
            <h2 class="font42">{{ $t("index.fourthTier.businessTitle") }}</h2>
            <p class="mt30 font20">{{ $t("index.fourthTier.businessTap1") }}</p>
          </div>
          <div class="flex-def mt60 color80 line-26 flex-between">
            <div style="width: 305px" class="flex-def flex-col flex-between">
              <div class="mt40">
                <img src="@/assets/images/index/index_project_05.png" />
                <p class="font20 color000 line-h36 mb5">
                  {{ $t("index.fourthTier.businessTap2") }}
                </p>
                <div class="line-h20">
                  <p>{{ $t("index.fourthTier.businessContent201") }}</p>
                </div>
              </div>
              <div class="mt70">
                <img src="@/assets/images/index/index_project_04.png" />
                <p class="font20 color000 line-h36 mb5">
                  {{ $t("index.fourthTier.businessTap3") }}
                </p>
                <div class="line-h20">
                  <p>{{ $t("index.fourthTier.businessContent301") }}</p>
                </div>
              </div>
            </div>
            <img class="index-business-center mt40" src="@/assets/images/index/index_project.png" />
            <div class="flex-c">
              <div>
                <img src="@/assets/images/index/index_project_03.png" />
                <p class="font20 color000 line-h36 mb5">
                  {{ $t("index.fourthTier.businessTap4") }}
                </p>
                <p class="line-h20">
                  {{ $t("index.fourthTier.businessContent4") }}
                </p>
              </div>
              <!-- <div class="mt70">
                <img src="@/assets/images/index/index_project_04.png" />
                <p class="font20 color000 line-h36 mb5">
                  {{ $t("index.fourthTier.businessTap5") }}
                </p>
                <div class="line-h20">
                  <p>{{ $t("index.fourthTier.businessContent501") }}</p>
                  <p>{{ $t("index.fourthTier.businessContent502") }}</p>
                </div>
              </div>
              <div class="mt70">
                <img src="@/assets/images/index/index_project_05.png" />
                <p class="font20 color000 line-h36 mb5">
                  {{ $t("index.fourthTier.businessTap6") }}
                </p>
                <div class="line-h20">
                  <p>{{ $t("index.fourthTier.businessContent601") }}</p>
                  <p>{{ $t("index.fourthTier.businessContent602") }}</p>
                  <p>{{ $t("index.fourthTier.businessContent603") }}</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 第五层 合作伙伴-->
      <!-- <div class="index-partner mb100">
        <h2 class="font42 align-c mb50">{{ $t("index.partnerTitle") }}</h2>
        <div class="flex-def flex-between">
          <div
            v-for="(item, index) in partnerImg1"
            :key="index"
            class="index-partner-box flex-def flex-col"
            :class="
              index !== partnerImg1.length - 1 ? 'index-partner-boeder' : ''
            "
          >
            <div v-for="(v, i) in item" :key="i">
              <img :src="v.img" />
            </div>
          </div>
        </div>
        <div class="align-c mt65">
          <el-button type="primary" round
            >{{ $t("oftenWord.learnMore") }}<i class="el-icon-arrow-right"></i
          ></el-button>
        </div>
      </div> -->
      <PartnerElement></PartnerElement>
      <!-- 第六层 精选案例 -->
      <div class="bgc-f3">
        <div class="index-show pt100">
          <h2 class="font42 align-c pb70">{{ $t("index.showTitle") }}</h2>
          <div class="index-show-box">
            <div v-for="(item, index) in showList" :key="index" class="index-show-img position-rel">
              <img :src="item.image" />
              <div class="index-show-bg position-abs" @click="goShowDetail(item.id)">
                <div class="index-show-intro position-abs align-c font20 line-h26 colorfff">
                  <p>{{ item.title }}</p>
                  <p>{{ item.desc }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="align-c mt65 pb70">
            <el-button type="primary" round @click="goToShow">{{ $t("oftenWord.learnMore") }}<i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutElement from "@/components/AboutElement.vue";
import PartnerElement from "@/components/PartnerElement.vue";

import { getBanners, getProjectsList } from "@/api/api";
import { apiAll } from "@/utils/request";
export default {
  components: {
    AboutElement,
    PartnerElement,
  },
  props: {},
  data() {
    return {
      // *图片
      imgBarnner: [],
      //发展历程
      // gowImage: [
      //   {
      //     img: require("@/assets/images/index/index_carousel_2013.png"),
      //     years: "2013 ———————",
      //     tap: this.$t("index.thirdTier.growTap1"),
      //     content: this.$t("index.thirdTier.growContent1"),
      //   },
      //   {
      //     img: require("@/assets/images/index/index_carousel_2016.png"),
      //     years: "2016 ———————",
      //     tap: this.$t("index.thirdTier.growTap2"),
      //     content: this.$t("index.thirdTier.growContent2"),
      //   },
      //   {
      //     img: require("@/assets/images/index/index_carousel_2019.png"),
      //     years: "2019 ———————",
      //     tap: this.$t("index.thirdTier.growTap3"),
      //     content: this.$t("index.thirdTier.growContent3"),
      //   },
      //   {
      //     img: require("@/assets/images/index/index_carousel_2021.png"),
      //     years: "2021 ———————",
      //     tap: this.$t("index.thirdTier.growTap4"),
      //     content: this.$t("index.thirdTier.growContent4"),
      //   },
      // ],
      //精选案例
      showList: [],
      imgUrl: "",
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.imgUrl = apiAll;
    this.getBanners();
    this.getProjectsList();
  },
  methods: {
    goShowDetail(id) {
      this.$router.push(`/showSon?detailId=${id}`);
    },
    goToShow() {
      this.$router.push("/caseShow");
    },

    //*banner轮播图
    getBanners() {
      getBanners({}).then((res) => {
        this.imgBarnner = res.data.data[0].banners;
      });
    },

    // *精选案例
    getProjectsList() {
      getProjectsList({}).then((res) => {
        this.showList = res.data.data.slice(0, 6);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  .index-banner {
    position: relative;
    height: 982px;
    background-color: #050c40;
    // background: #050c40 url("@/assets/images/index/index_banner.jpg") no-repeat
    //   center center;
    // *组件
    /deep/.el-carousel__indicators--horizontal {
      bottom: 40px;
      // left: 40%;
    }
    // /deep/.el-carousel__indicator--horizontal {
    //   padding: 0;
    // }
    /deep/.el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    /deep/.el-carousel .el-carousel__indicator.is-active {
      color: #fff;
      position: relative;
      // top: -22px;
      word-spacing: 30px;
      font-size: 20px;
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e75012;
        color: #fff;
      }
      button:hover {
        background-color: #e75012 !important;
      }
    }
  }
  .index-main {
    //第一层
    .index-decision {
      width: 1200px;
      margin: 0 auto;
      img {
        width: 84px;
      }
      .index-decision-box {
        width: 390px;
        height: 290px;
        background-color: #f3f8fd;
      }
      .index-decision-main:hover .index-box-bg {
        display: block;
      }

      .index-box-bg {
        top: 0;
        width: 390px;
        height: 290px;
        background-color: #e75012;
        display: none;
        padding: 45px;
        box-sizing: border-box;
      }
    }
    //第二层
    // .index-about {
    //   height: 950px;
    //   background: #f3f8fd url("@/assets/images/index/index_about_bg.png")
    //     no-repeat center bottom;
    //   .index-about-main {
    //     width: 1200px;
    //     margin: 0 auto;

    //     img {
    //       width: 556px;
    //       height: 584px;
    //     }
    //   }

    //第三层
    // .index-grow {
    //   width: 100%;
    //   top: 784px;
    //   .index-grow-main {
    //     width: 1200px;
    //     height: 740px;
    //     margin: 0 auto;
    //     box-sizing: border-box;
    //     background: url("@/assets/images/index/index_carousel_bg.png")
    //       no-repeat;

    //     .index-grow-carousel {
    //       padding: 70px 90px;
    //     }
    //   }
    // }
    // }

    //第四层
    .index-business {
      height: 860px;
      background-color: #f3f8fd;
      .index-business-main {
        width: 1300px;
        margin: 0 auto;

        .index-business-center {
          width: 742px;
          height: 408px;
        }
      }
    }

    // *第五层
    // .index-partner {
    //   width: 1200px;
    //   margin: 0 auto;
    //   .index-partner-box {
    //     width: 168px;
    //   }
    //   .index-partner-boeder {
    //     border-right: 1px solid #d2d2d2;
    //   }
    //   img {
    //     width: 110px;
    //     height: 72px;
    //   }
    // }

    // *第六层
    .index-show {
      width: 1200px;
      margin: 0 auto;

      .index-show-box {
        display: grid;
        grid-template-columns: repeat(3, 381px);
        grid-row-gap: 28px;
        grid-column-gap: 28px;
      }
      .index-show-img:hover .index-show-bg {
        display: block;
      }
      .index-show-bg {
        display: none;
        width: 100%;
        height: 100%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        .index-show-intro {
          width: 91%;
          left: 5%;
          padding: 15px 0;
          bottom: 15px;
        }
      }
      img {
        width: 381px;
        height: 355px;
      }
    }
  }
}
</style>
